import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import './custom.scss';
import './fonts/Roboto-Regular.ttf';
import SwaggerNav from './components/SwaggerNav';
import Login from './components/Login';
import Home from './components/Home';
import Tablet from './components/Tablet';
import ResetPassword from './components/ResetPassword';
import { clarity } from 'react-microsoft-clarity';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_BASE_MS_CLARITY_ID) {
      console.log("setting up clarity...");
      
      // Start seeing data on the Clarity dashboard
      clarity.init(process.env.REACT_APP_BASE_MS_CLARITY_ID);
    
      // Cookie consent
      clarity.consent();
    }
  }, []);

  return (
    <Layout>
      <Routes>
        {/* {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })} */}
        <Route index={true} element={<Login />} />
        <Route path="/swagger" element={<SwaggerNav />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/tablet">
          <Route path=":ani" element={<Tablet />} />
          <Route path="" element={<Tablet />} />
        </Route>
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
      </Routes>
    </Layout>
  );
}

export default App;