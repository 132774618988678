import React, { useState } from 'react';
import { Navbar, Nav, NavLink, Container, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from './hooks/AuthContext';
import SignOutModal from './SignOutModal';

//const logo = require("../images/prodigy-login-logo.png");
const logo = require("../images/white_prodigy_text_logo.png");

const NavMenu = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const { auth } = useAuth();

  const handleLogout = async () => {
    setShowLogoutModal(true);
  };

  const renderSwaggerNavItem = () => {
    // Only render the swagger nav item if we're currently in a dev environment
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
      return (
        <Nav.Item>
          <Nav.Link as={Link} className="nav-item-swagger-ui" to="/swagger" target="_blank">Swagger UI</Nav.Link>
        </Nav.Item>
      );
    }
  };

  return (
    <header>
      {auth ? (
        <Navbar expand={false} className="col-12 mb-3 psi-navbar" bg="dark" variant="dark">
          <Container fluid>
            <Navbar.Toggle className="col-2 col-md-1" aria-controls={`offcanvasNavbar-expand-false`} />
            <div className="navbar-logo-container col-8 col-md-10">
              <img src={logo} alt="Prodigy Solutions" />
            </div>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-false`}
              aria-labelledby={`offcanvasNavbarLabel-expand-false`}
              placement="start"
              className="psi-navbar"
            >
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <div className="top-nav-items">
                    {/* <NavLink as={Link} className="text-light" to="/">Home</NavLink> */}
                    {renderSwaggerNavItem()}
                  </div>
                  <div className="bottom-nav-items">
                    <NavLink as={Link} className="text-light" to="/login" onClick={handleLogout}>
                      <span className="psi-icon psi-icon-invert-color icon-logout inline-block"></span>
                      <span className="inline-block">&nbsp;&nbsp;Sign Out</span>
                    </NavLink>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
          <SignOutModal show={showLogoutModal} setShowLogoutModal={setShowLogoutModal} onHide={() => setShowLogoutModal(false)} />
        </Navbar>
      ) : (
        <div className="login-logo-container">
          <img src={logo} alt="Prodigy Solutions" />
        </div>
      )}
    </header>
  );
};

export default NavMenu;