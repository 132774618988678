import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import useInmateService from "../services/InmateService";


const InmateTypeahead = ({handleChangeSelectedInmate, includeClearIcon, selectedInmate, siteId}: any) => {
    const [inmateOptions, setInmateOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [typeaheadRef, setTypeaheadRef] = useState({} as any);

    const { searchInmates } = useInmateService();


    useEffect(() => {
        // Sometimes when an inmate is selected, the dropdown opens.
        // So, close the menu when an inmate is selected
        if (typeaheadRef && typeaheadRef.blur) {
            typeaheadRef.blur();
        }
    }, [selectedInmate]);

    
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    const handleClearInmate = () => {
        handleChangeSelectedInmate([]);
    }

    const handleSearchInmates = async (search: string) => {
        setLoading(true);

        const result = await searchInmates(search, siteId);
        setInmateOptions(result.data);
        
        setLoading(false);
    }

    const renderInput = ({inputRef, referenceElementRef, ...props}: any) => {
        return (
            <div className="input-icon">
                <div className="rbt psi-text-field-container">
                    <input {...props}
                        className={"rbt-input-main form-control rbt-input" + props.className}
                        ref={(input) => {
                            inputRef(input);
                            referenceElementRef(input);
                        }}
                    />
                    {selectedInmate && (
                    <span
                        className="icon-cancel icon-inline"
                        onClick={() => {
                            handleClearInmate();

                            // Clear the input value in the typeahead text field
                            // @ts-ignore
                            typeaheadRef.clear();
                            typeaheadRef.focus();
                        }}
                    />
                    )}
                </div>
            </div>
    )};


    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id="inmateSearchTypeahead"
            labelKey={(option: any) => `${option.name.trim()} (Act # ${option.apin.trim()})`}
            isLoading={loading}
            minLength={3}
            maxResults={3}
            onSearch={handleSearchInmates}
            options={inmateOptions}
            onChange={handleChangeSelectedInmate}
            selected={selectedInmate}
            placeholder="Name, Account #"
            renderMenuItemChildren={(option: any) => (
                <span>{`${option.name.trim()} (Act # ${option.apin.trim()})`}</span>
            )}
            ref={typeahead => setTypeaheadRef(typeahead as any)}
            {...(includeClearIcon && { renderInput: renderInput })} // Add the attribute renderInput={renderInput} if includeClearIcon is true
        />
    );
}

export default InmateTypeahead;