import { useEffect } from 'react';

const SwaggerNav = () => {
    const navigateToSwaggerUi = () => {
        // Swagger UI uses a different base URL than the rest of the application so we must navigate to it manually
        window.location.href = 'https://localhost:7072/swagger/index.html';
    }

    useEffect(() => {
        navigateToSwaggerUi();
    }, []);

    return(<></>);
}

export default SwaggerNav;