import { LoginArgs } from "../models/args/LoginArgs";
import { ResetPasswordArgs } from "../models/args/ResetPasswordArgs";
import { AttemptResult } from "../models/dtos/AttemptResult";
import { Domain } from "../models/dtos/Domain";
import useBaseService from "./BaseService";

const useAuthService = () => {
    const { get, post } = useBaseService();

    return {
        forgotPassword: async (usernameOrEmail: string): Promise<AttemptResult> => {
            const parameters = [
                {key: 'usernameOrEmail', value: usernameOrEmail},
                {key: 'requestScheme', value: process.env.REACT_APP_BASE_SCHEME},
                {key: 'requestHostUri', value: process.env.REACT_APP_BASE_CLIENT_HOST_URI}
            ];
            const response = await get('Auth/ForgotPassword', parameters);
            return response?.data;
        },
        getCurrentUser: async () => {
            return await get('Auth/CurrentUser');
        },
        getDomainByToken: async (token: string): Promise<Domain> => {
            const parameters = [{key: 'token', value: token}];
            const response = await get('Auth/GetDomainByToken', parameters);
            return response?.data;
        },
        login: async (args: LoginArgs) => {
            return await post(`Auth/Login`, args);
        },
        logout: async () => {
            return await get('Auth/Logout');
        },
        resetPassword: async (args: ResetPasswordArgs) => {
            return await post('Auth/ResetPassword', args);
        }
    }
}

export default useAuthService;