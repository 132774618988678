import { createContext, useContext, useEffect, useState } from 'react';
import useAuthService from '../../services/AuthService';
import { useLocation } from 'react-router-dom';

export class CreatedAuthContext {
  public auth: boolean | null | undefined;
  public setAuth: any;
  public user: any;

  public constructor(auth: boolean | null | undefined, setAuth: any, user: any) {
    this.auth = auth;
    this.setAuth = setAuth;
    this.user = user;
  }
}

const AuthContext = createContext({
  auth: null,
  setAuth: () => { },
  user: null,
} as CreatedAuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const location = useLocation();

  const { getCurrentUser } = useAuthService();
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const isAuth = async () => {
      try {
        const allowedPathList = [
          "/resetpassword",
          "/login"
        ];
        
        let checkUser = true;
        // Also check if the path is just a slash which defaults to the login page
        if (location.pathname === "/"){
          checkUser = false;
        } else {
          for (let i = 0; i < allowedPathList.length; i++) {
            const path = location.pathname.toLowerCase();
            const allowedPath = allowedPathList[i].toLowerCase();
            if (path.startsWith(allowedPath)){
              checkUser = false;
              break;
            }
          }
        }

        if (checkUser) {
          const result = await getCurrentUser();
          setUser(result.data);
          setAuth(true);
        } else {
          setAuth(false);
        }
      } catch (err: any) {
        console.log("error", err);
        switch (err.response.status) {
          case 400:
            console.log(err.response.data.message);
            break;
          case 401:
            setUser(null);
            break;
          default:
            console.error(err);
            break;
        }
        setUser(null);
        setAuth(false);
      };
    };

    isAuth();
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, user }}>
      {children}
    </AuthContext.Provider>
  );
};