import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useLoadingOverlay } from './hooks/LoadingOverlayContext';
import { useState } from 'react';
import { Form, FormGroup, FormFeedback, Label, Input } from "reactstrap";
import useAuthService from '../services/AuthService';

class ForgotPasswordForm {
    public usernameOrEmail!: string;
}

class ForgotPasswordFormErrors {
    public usernameOrEmail!: string;

    hasErrors(): boolean {
        return !!this.usernameOrEmail;
    }
}

const ForgotPasswordModal = ({ onHide, setShowModal, show }: any) => {
    const { loading, setLoading } = useLoadingOverlay();
    const { forgotPassword } = useAuthService();

    //const [usernameOrEmail, setUsernameOrEmail] = useState(null);
    const [form, setForm] = useState({} as ForgotPasswordForm);
    const [errors, setErrors] = useState({} as ForgotPasswordFormErrors);
    

    const handleCancelClick = () => {
        setShowModal(false);
    }

    const handleConfirmClick = () => {
        handleForgotPassword();
    }

    const handleForgotPassword = async () => {
        setLoading(true);

        try {
            const formErrors = validateForm();
            if (formErrors.hasErrors()) {
                setErrors(formErrors);
            } else {
                // @ts-ignore -- usernameOrEmail cannot be null here because of hasErrors() check
                await forgotPassword(form.usernameOrEmail);
                resetInput();
                toast.success("If the username or email address is in our system, an email has been sent to reset your password!");
                onHide();
            }
        } catch (err) {
            console.error(err);
            toast.error("Failed to send reset password email");
        }

        setLoading(false);
    }

    const resetInput = () => {
        setField("usernameOrEmail", "");
    }

    const setField = (field: string, value: string) => {
        setForm({
            ...form,
            [field]: value
        });

        // @ts-ignore
        if (errors[field]) {
            const newErrors = Object.assign(new ForgotPasswordFormErrors(), errors);

            // Remove errors from field since it already exists
            // @ts-ignore
            newErrors[field] = null;
            setErrors(newErrors);
        }
    }

    const validateForm = () => {
        const { usernameOrEmail } = form;
        const newErrors = new ForgotPasswordFormErrors();

        if (!usernameOrEmail) {
            newErrors.usernameOrEmail = "please enter your Username or Email";
        }

        return newErrors;
    }

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="login-page">
                    <FormGroup>
                        <Label for="usernameOrEmail" className={(errors.usernameOrEmail) ? "psi-red" : ""}>Username / Email{(errors.usernameOrEmail) ? " - " + errors.usernameOrEmail : ""}</Label>
                        <Input
                            type="text"
                            placeholder="Username or Email"
                            className="form-control"
                            defaultValue={form.usernameOrEmail}
                            onChange={(e) => { setField("usernameOrEmail", e.target.value) }}
                        ></Input>
                        <FormFeedback type="invalid" className="psi-red">
                            {errors.usernameOrEmail}
                        </FormFeedback>
                    </FormGroup>
                    <div className="btn-container-horizontal row">
                        <button type="button" onClick={handleCancelClick} className="psi-btn psi-btn-default col">
                            Cancel
                        </button>
                        <div className="col-1"></div>
                        <button type="button" onClick={handleConfirmClick} className="psi-btn psi-btn-primary col">
                            Confirm
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ForgotPasswordModal;