import { useEffect, useState } from "react";
import useDomainFacilityService from "../../services/DomainFacilityService";
import { Facility } from "../../models/dtos/Facility";

const useFacilities = () => {
    const { getFacilities } = useDomainFacilityService();
    const [facilities, setFacilities] = useState([] as Facility[]);

    useEffect(() => {
        const loadAuthorizedFacilities = async () => {
            try {
                const result = await getFacilities();
                setFacilities(result);
            } catch (err) {
                console.error(err);
            }
        }

        loadAuthorizedFacilities();
    }, []);

    return facilities;
}

export default useFacilities;