import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import useAuthService from '../services/AuthService';
import { useAuth } from './hooks/AuthContext';

const SignOutModal = (props: any) => {
    const { logout } = useAuthService();
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await logout();

        setAuth(false);
        props.setShowLogoutModal(false);
        navigate('/login');
    }

    const handleCancel = () => {
        props.setShowLogoutModal(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <p className="text-center">Are you sure you want to sign out?</p>
                <div className="btn-container">
                    <button type="button" onClick={handleSignOut} className="psi-btn psi-btn-primary">
                        <span className="psi-icon psi-icon-invert-color icon-logout inline-block"></span>
                        <span className="inline-block">&nbsp;&nbsp;Sign Out</span>
                    </button>
                    <button type="button" onClick={handleCancel} className="psi-btn psi-btn-default">
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SignOutModal;