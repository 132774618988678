import { Facility } from "../models/dtos/Facility";
import useBaseService from "./BaseService";

const useDomainFacilityService = () => {
    const { get } = useBaseService();

    return {
        getFacilities: async (): Promise<Facility[]> => {
            const response = await get('DomainFacility/Facilities');
            return response?.data;
        }
    }
}

export default useDomainFacilityService;