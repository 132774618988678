import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/hooks/AuthContext';

const useBaseService = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    
    const BASE_SCHEME = process.env.REACT_APP_BASE_SCHEME;
    const BASE_HOST_URI = process.env.REACT_APP_BASE_HOST_URI;

    // Make sure axios requests send cookies
    axios.defaults.withCredentials = true;

    const get = async (url: string, parameters: any[] = []) => {
        let fullUrl = `${BASE_SCHEME}://${BASE_HOST_URI}/${url}`;
        if (parameters && parameters.length > 0) {
            let counter = 0;
            parameters.forEach((param) => {
                if (counter === 0) {
                    fullUrl += "?";
                } else {
                    fullUrl += "&";
                }

                fullUrl += `${param.key}=${param.value}`;

                counter++;
            });
        }

        try {
            return await axios.get(fullUrl);
        } catch (err: any) {
            // If the user is unauthorized, they are either doing something they shouldn't be doing
            // or their token has expired. Log them out and redirect them to the login page.
            if (err.response.status === 401) {
                await logoutAndRedirect();
                throw err;
            } else {
                throw err;
            }
        }
    }

    const post = async (url: string, data?: any) => {
        try {
            return await axios.post(`${BASE_SCHEME}://${BASE_HOST_URI}/${url}`, data);
        } catch (err: any) {
            // If the user is unauthorized, they are either doing something they shouldn't be doing
            // or their token has expired. Log them out and redirect them to the login page.
            if (err.response.status === 401) {
                await logoutAndRedirect();
            } else {
                throw err;
            }
        }
    }

    const logoutAndRedirect = async () => {
        await get('Auth/Logout');
        setAuth(false);
        navigate('/login');
    }

    return {
        get,
        post
    }
}

export default useBaseService;