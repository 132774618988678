import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import useCircuitService from "../services/CircuitService";
import { Circuit } from "../models/dtos/Circuit";


const CircuitTypeahead = ({handleChangeSelectedCircuit, includeClearIcon, selectedCircuit, siteId, facilities}: any) => {
    const [circuitOptions, setCircuitOptions] = useState([] as Circuit[]);
    const [loading, setLoading] = useState(false);
    const [typeaheadRef, setTypeaheadRef] = useState({} as any);

    const { searchCircuits } = useCircuitService();

    
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    const handleClearCircuit = () => {
        handleChangeSelectedCircuit([]);
    }

    const handleSearchCircuits = async (search: string) => {
        setLoading(true);
        const result = await searchCircuits(search, siteId);
        setCircuitOptions(result);
        
        setLoading(false);
    }

    const renderInput = ({inputRef, referenceElementRef, ...props}: any) => {
        return (
            <div className="input-icon">
                <div className="rbt psi-text-field-container">
                    <input {...props}
                        className={"rbt-input-main form-control rbt-input" + props.className}
                        ref={(input) => {
                            inputRef(input);
                            referenceElementRef(input);
                        }}
                    />
                    {selectedCircuit && (
                    <span
                        className="icon-cancel icon-inline"
                        onClick={() => {
                            handleClearCircuit();

                            // Clear the input value in the typeahead text field
                            // @ts-ignore
                            typeaheadRef.clear();
                            typeaheadRef.focus();
                        }}
                    />
                    )}
                </div>
            </div>
    )};


    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id="circuitSearchTypeahead"
            labelKey={(option: any) => `${option.description.trim()} (${option.ani})`}
            isLoading={loading}
            minLength={3}
            maxResults={3}
            onSearch={handleSearchCircuits}
            options={circuitOptions}
            onChange={handleChangeSelectedCircuit}
            selected={selectedCircuit}
            placeholder="Tablet ID"
            useCache={false}
            renderMenuItemChildren={(option: Circuit) => {
                // Add the facility name to the display if the current account has access to multiple facilities
                if (facilities.length > 1) {
                    let facilityName: string = "Unknown Facility";
                    for (let i = 0; i < facilities.length; i++){
                        if (facilities[i].siteId === option.siteId){
                            facilityName = facilities[i].facilityName;
                            break;
                        }
                    }

                    return (<span>{`${option.description.trim()} (${option.ani}) - ${facilityName}`}</span>)
                } else {
                    return (<span>{`${option.description.trim()} (${option.ani})`}</span>)
                }
            }
            }
            ref={typeahead => setTypeaheadRef(typeahead as any)}
            {...(includeClearIcon && { renderInput: renderInput })} // Add the attribute renderInput={renderInput} if includeClearIcon is true
        />
    );
}

export default CircuitTypeahead;