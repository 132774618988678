import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, FormGroup, FormFeedback, Label } from "reactstrap";
import useCircuitService from "../services/CircuitService";
import useInmateService from "../services/InmateService";
import { useLoadingOverlay } from "./hooks/LoadingOverlayContext";
import { toast } from 'react-toastify';
import ReturnCircuitModal from "./ReturnCircuitModal";
import { Circuit } from "../models/dtos/Circuit";
import { InmateAccount } from "../models/dtos/InmateAccount";
import { Option } from "react-bootstrap-typeahead/types/types";
import { TabletRental } from "../models/dtos/TabletRental";
import InmateTypeahead from "./InmateTypeahead";
import CircuitTypeahead from "./CircuitTypeahead";
import useFacilities from "./hooks/useFacilities";


class TabletForm {
    public circuit!: Circuit;
    public inmate!: InmateAccount;
}

class TabletFormErrors {
    public circuit!: string;
    public inmate!: string;

    hasErrors(): boolean {
        return (!!this.circuit || !!this.inmate);
    }
}

const Tablet = () => {
    const { ani } = useParams();

    const facilities = useFacilities();

    const { assignCircuit, getAssignedCircuit, getCircuit, getCircuitAssignability } = useCircuitService();
    const { getInmateAssignability, getInmate } = useInmateService();
    const { setLoading } = useLoadingOverlay();
    
    const [circuitRental, setCircuitRental] = useState({} as TabletRental);
    const [siteId, setSiteId] = useState(null as string | null);

    const [form, setForm] = useState({} as TabletForm);
    const [errors, setErrors] = useState({} as TabletFormErrors);
    const [selectedCircuit, setSelectedCircuit] = useState([] as Circuit[]);
    const [selectedInmate, setSelectedInmate] = useState([] as InmateAccount[]);
    
    const [showReturnCircuitModal, setShowReturnCircuitModal] = useState(false);
    const [tabletInUse, setTabletInUse] = useState(true);

    const [freeRental, setFreeRental] = useState(false);

    const changeSelectedCircuit = async (options: Option[], handleLoading: boolean = true) => {
        const circuits = options as Circuit[]; // So that the onChange attribute doesn't complain
        setSelectedCircuit(circuits);
        setField('circuit', circuits[0]);

        if (circuits[0]) {
            if (handleLoading) {
                setLoading(true);
            }

            setSiteId(circuits[0].siteId);

            // Check to see whether circuit is assigned to an inmate already
            const assignedCircuit = await getAssignedCircuit(circuits[0].ani);
            if (assignedCircuit) {
                setTabletInUse(true);
                // This tablet is assigned, so automatically select the inmate it is assigned to
                setCircuitRental(assignedCircuit);
                const inmateAccount = await getInmate(assignedCircuit.inmatePin, assignedCircuit.siteId);
                if (inmateAccount) {
                    changeSelectedInmate([inmateAccount], handleLoading);
                } else {
                    console.log('pin', assignedCircuit.inmatePin);
                    console.log('siteId', assignedCircuit.siteId);
                    console.error('failed to find inmate', inmateAccount);
                    toast.error('Failed to get inmate data for tablet rental');
                }
            } else {
                // Check whether or not the circuit should be allowed to be assigned to an inmate.
                // If it is, then reset the circuit errors. Otherwise, set the circuit errors to the returned reasons
                const newErrors = new TabletFormErrors();
                newErrors.inmate = errors.inmate;
                if (circuits && circuits.length > 0 && !!circuits[0].ani) {
                    const assignableResult = await getCircuitAssignability(circuits[0].ani);
                    if (!assignableResult.isAssignable) {
                        newErrors.circuit = "";
                        for (let i = 0; i < assignableResult.reasons.length; i++) {
                            if (i === 0) {
                                newErrors.circuit = assignableResult.reasons[i];
                            } else {
                                newErrors.circuit += ` & ${assignableResult.reasons[i]}`;
                            }
                        }
                    }
                }
                
                setErrors(newErrors);

                setTabletInUse(false);
                setCircuitRental({} as TabletRental);
            }

            if (handleLoading) {
                setLoading(false);
            }
        } else {
            setTabletInUse(false);
            setCircuitRental({} as TabletRental);

            if (!selectedInmate[0]) {
                setSiteId(null);
            }
        }
    }

    const changeSelectedInmate = async (options: Option[], handleLoading: boolean = true) => {
        const inmates = options as InmateAccount[]; // So that the onChange attribute doesn't complain
        setSelectedInmate(inmates);
        setField('inmate', inmates[0]);

        // Check whether or not the inmate is allowed to be assigned a tablet currently.
        // If they are, then reset the inmate errors. Otherwise, set the inmate errors to the returned reasons
        const newErrors = new TabletFormErrors();
        newErrors.circuit = errors.circuit;
        if (inmates && inmates.length > 0 && !!inmates[0].pin) {
            if (handleLoading) {
                setLoading(true);
            }
            
            setSiteId(inmates[0].siteId);
            const assignableResult = await getInmateAssignability(inmates[0].pin, inmates[0].siteId, freeRental);
            if (!assignableResult.isAssignable) {
                newErrors.inmate = "";
                for (let i = 0; i < assignableResult.reasons.length; i++) {
                    if (i === 0) {
                        newErrors.inmate = assignableResult.reasons[i];
                    } else {
                        newErrors.inmate += ` & ${assignableResult.reasons[i]}`;
                    }
                }
            }

            if (handleLoading) {
                setLoading(false);
            }
        } else {
            if (!selectedCircuit[0]) {
                setSiteId(null);
            }
        }
        setErrors(newErrors);
    }

    const handleAssignTablet = async () => {
        setLoading(true);

        // Validate the form before sending
        const formErrors = validateAssignTablet();
        if (formErrors.hasErrors()) {
            setErrors(formErrors);
        } else {
            // Form is validated so assign the tablet
            const { circuit, inmate } = form;

            const args = {
                ani: circuit.ani,
                inmatePin: inmate.pin,
                siteId: inmate.siteId,
                inmateRefId: inmate.inmateRefId,
                freeRental: freeRental
            };
            
            try {
                const result = await assignCircuit(args);

                if (result) {
                    setCircuitRental(result);
                    setTabletInUse(true);
                    toast.success("Tablet assigned successfully");
                } else {
                    console.error("AssignTablet returned invalid", result);
                    toast.error("Failed to assign tablet");
                }
            } catch (err) {
                console.error(err);
                toast.error("Failed to assign tablet");
            }
        }

        setLoading(false);
    }

    const handleChangeFreeRental = async (newVal: boolean) => {
        setFreeRental(newVal);

        // Check whether or not the inmate is allowed to be assigned a tablet currently.
        // If they are, then reset the inmate errors. Otherwise, set the inmate errors to the returned reasons
        const newErrors = new TabletFormErrors();
        newErrors.circuit = errors.circuit;
        if (selectedInmate && selectedInmate.length > 0 && !!selectedInmate[0].pin) {
            setLoading(true);
            
            const assignableResult = await getInmateAssignability(selectedInmate[0].pin, selectedInmate[0].siteId, newVal);
            if (!assignableResult.isAssignable) {
                newErrors.inmate = "";
                for (let i = 0; i < assignableResult.reasons.length; i++) {
                    if (i === 0) {
                        newErrors.inmate = assignableResult.reasons[i];
                    } else {
                        newErrors.inmate += ` & ${assignableResult.reasons[i]}`;
                    }
                }
            }
            
            setLoading(false);
        }

        setErrors(newErrors);
    }

    const handleReturnTablet = async () => {
        const formErrors = validateReturnTablet();
        if (formErrors.hasErrors()) {
            setErrors(formErrors);
        } else {
            setShowReturnCircuitModal(true);
        }
    }

    const handleReturnCircuitModalHide = () => {
        changeSelectedCircuit([]);
        changeSelectedInmate([]);
        setShowReturnCircuitModal(false);
    }

    const validateAssignTablet = (): TabletFormErrors => {
        // First check to see if there is already an existing error for the selected inmate
        if (errors.inmate){
            return errors;
        }

        const { inmate, circuit } = form;
        const newErrors = new TabletFormErrors();

        if (!circuit) {
            newErrors.circuit = "please select a circuit";
        }

        if (!inmate) {
            newErrors.inmate = "please select an inmate";
        }

        return newErrors;
    }

    const validateReturnTablet = (): TabletFormErrors => {
        const { circuit } = form;
        const newErrors = new TabletFormErrors();
        
        if (!circuit) {
            newErrors.circuit = "please select a circuit";
        }

        return newErrors;
    }

    const setField = (field: string, value: any) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value
        }));

        // @ts-ignore
        if (errors[field]) {
            const newErrors = Object.assign(new TabletFormErrors(), errors);

            // Remove errors from field since it already exists
            // @ts-ignore
            newErrors[field] = null;
            setErrors(newErrors);
        }
    }

    useEffect(() => {
        const loadDefaultCircuit = async () => {
            try {
                // If the ANI was passed as a parameter to this page, automatically select the correct circuit
                if (ani) {
                    // Also, load circuit options so that the typeahead doesn't complain
                    // const circuitOptions = await searchCircuits(ani);
                    // setCircuitOptions
                    
                    setLoading(true);
                    const circuit = await getCircuit(ani);
                    if (circuit) {
                        // selected circuit expects an array even though there can only be one
                        changeSelectedCircuit([circuit], false);
                    }
                    
                    setLoading(false);
                }
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }

        loadDefaultCircuit();
    }, []);

    


    return (
        <div>
            <Form className="tablet-page">
                <FormGroup className="field-container">
                    <Label for="circuit" className={(errors.circuit) ? "psi-red" : ""}>Tablet Name{(errors.circuit) ? " - " + errors.circuit : ""}</Label>
                    {selectedCircuit.length > 0 ? 
                        <div className="tablet-status-container">
                            <span className={`tablet-status-circle ${(tabletInUse) ? "tablet-in-use-circle" : "tablet-available-circle"}`}></span>
                            <span>&nbsp;&nbsp;{(tabletInUse) ? "In Use" : "Available"}</span>
                        </div>
                        : <></>
                    }
                    <CircuitTypeahead handleChangeSelectedCircuit={changeSelectedCircuit} includeClearIcon={true} selectedCircuit={selectedCircuit} siteId={siteId} facilities={facilities} />
                    <FormFeedback type="invalid" className="psi-red">
                        {errors.circuit}
                    </FormFeedback>
                </FormGroup>
                <FormGroup className="field-container">
                    <Label for="inmate" className={(errors.inmate) ? "psi-red" : ""}>Inmate Lookup{(errors.inmate) ? " - " + errors.inmate : ""}</Label>
                    <InmateTypeahead handleChangeSelectedInmate={changeSelectedInmate} includeClearIcon={true} selectedInmate={selectedInmate} siteId={siteId} />
                    <FormFeedback type="invalid" className="psi-red">
                        {errors.inmate}
                    </FormFeedback>
                </FormGroup>
                {
                    // Only show the Paid/Free option if a circuit is selected and available
                    (!tabletInUse && selectedCircuit.length !== 0) &&
                    <FormGroup className="field-container">
                        <div onClick={() => handleChangeFreeRental(!freeRental)}>
                            <span className="col font-scale" title="If checked, the inmate will not be charged but will only be able to use the free apps">Free Apps Only</span>
                            <span className={"psi-checkbox margin-left-10 col" + ((freeRental) ? " checked" : "")} title="If checked, the inmate will not be charged but will only be able to use the free apps" />
                        </div>
                    </FormGroup>
                }
                <div className="btn-container">
                    <button type="button" onClick={handleAssignTablet} className="psi-btn psi-btn-primary" disabled={tabletInUse || selectedCircuit.length === 0 || selectedInmate.length === 0 || !!errors.inmate || !!errors.circuit}>
                        Assign Tablet
                    </button>
                    <button type="button" onClick={handleReturnTablet} className="psi-btn psi-btn-primary" disabled={!tabletInUse || selectedCircuit.length === 0 || !!errors.inmate || !!errors.circuit}>
                        Return Tablet
                    </button>
                </div>
            </Form>
            <ReturnCircuitModal
                show={showReturnCircuitModal}
                setShowModal={setShowReturnCircuitModal}
                onHide={handleReturnCircuitModalHide}
                circuitRentalId={circuitRental.id}
                circuit={form && form.circuit ? form.circuit : null}
                inmate={form && form.inmate ? form.inmate : null}
            />
        </div>
    );
}

export default Tablet;