import { InmateAccount } from "../models/dtos/InmateAccount";
import { InmateAssignableResult } from "../models/dtos/InmateAssignableResult";
import useBaseService from "./BaseService";

const useInmateService = () => {
    const { get } = useBaseService();

    return {
        getInmateAssignability: async (pin: string, siteId: string, freeRental: boolean): Promise<InmateAssignableResult> => {
            const parameters = [{key: 'pin', value: pin}, {key: 'siteId', value: siteId}, {key: 'freeRental', value: freeRental}];
            const response = await get('Inmate/GetAssignability', parameters);
            return response?.data;
        },
        getInmate: async (pin: string, siteId: string): Promise<InmateAccount> => {
            const parameters = [{key: 'pin', value: pin}, {key: 'siteId', value: siteId}];
            const response = await get('Inmate', parameters);
            return response?.data;
        },
        searchInmates: async (search: string, siteId: string) => {
            const parameters = [{key: 'search', value: search}];
            if (siteId) {
                parameters.push({key: 'siteId', value: siteId});
            }
            
            return await get('Inmate/Search', parameters);
        }
    }
}

export default useInmateService;