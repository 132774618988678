import Modal from 'react-bootstrap/Modal';
import useCircuitService from '../services/CircuitService';
import { toast } from 'react-toastify';
import { useLoadingOverlay } from './hooks/LoadingOverlayContext';
import { useEffect, useState } from 'react';
import { TabletRentalDamageType } from '../models/dtos/TabletRentalDamageType';

const ReturnCircuitModal = ({ onHide, setShowModal, show, circuitRentalId, circuit, inmate }: any) => {
    const { getCircuitRentalDamageTypes, returnCircuit } = useCircuitService();
    const { setLoading } = useLoadingOverlay();

    const [conditionGood, setConditionGood] = useState(true);
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [damagedByInmate, setDamagedByInmate] = useState(true);
    const [removeFromCirculation, setRemoveFromCirculation] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [damageTypes, setDamageTypes] = useState([] as TabletRentalDamageType[]);


    useEffect(() => {
        const loadCircuitDamageTypes = async () => {
            try {
                setLoading(true);
                const result = await getCircuitRentalDamageTypes();
                setDamageTypes(result);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }

        loadCircuitDamageTypes();
        // eslint-disable-next-line
    }, []);

    const handleBack = () => {
        setPageNumber(1);
    }

    const handleCancel = () => {
        setShowModal(false);
    }

    const handleFirstConfirmClick = () => {
        if (conditionGood) {
            handleReturnCircuit();
        } else {
            setPageNumber(2);
        }
    }

    const handleReturnCircuit = async () => {
        setLoading(true);

        try {
            const args = {
                id: circuitRentalId,
                damaged: !conditionGood,
                damagedByInmate: damagedByInmate,
                damageTypeList: damageTypes.filter(dt => dt.checked).map(dt => dt.id),
                removeFromCirculation: conditionGood ? false : removeFromCirculation,
                requestScheme: process.env.REACT_APP_BASE_SCHEME,
                requestUri: process.env.REACT_APP_BASE_HOST_URI
            };

            await returnCircuit(args);
            resetInput();
            toast.success("Tablet returned successfully");
            onHide();
        } catch (err) {
            console.error(err);
            toast.error("Failed to return tablet");
        }

        setLoading(false);
    }

    const handleDamageTypeChange = (id: number) => {
        const newDamageTypes = Object.assign([] as TabletRentalDamageType[], damageTypes);

        // Manually loop through so we can also set disabled status for confirm button
        let atLeastOneChecked: boolean = false;
        for (let i = 0; i < newDamageTypes.length; i++){
            if (damageTypes[i].id === id) {
                damageTypes[i].checked = !damageTypes[i].checked;
            }

            if (damageTypes[i].checked) {
                atLeastOneChecked = true;
            }
        }

        setConfirmDisabled(!atLeastOneChecked);
        setDamageTypes(newDamageTypes);
    }

    const resetInput = () => {
        setConditionGood(true);
        setConfirmDisabled(true);
        setDamagedByInmate(true);
        setRemoveFromCirculation(true);
        setPageNumber(1);

        const resetDamageTypes = Object.assign([] as TabletRentalDamageType[], damageTypes);
        resetDamageTypes.map((damageType: TabletRentalDamageType) => {
            damageType.checked = false;
            return damageType;
        });
        setDamageTypes(resetDamageTypes);
    }

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {pageNumber === 1 &&
                <>
                    <Modal.Header>
                        <Modal.Title>Confirm Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="return-tablet-modal-body-container">
                            <div className="row" style={{marginTop: "0px"}}>
                                <span className="title">Tablet Name</span>
                            </div>
                            <div className="row">
                                <span className="field">{circuit && circuit.description ? circuit.description.trim() : "N/A"}</span>
                            </div>
                            <div className="row">
                                <span className="col col-lg-3 title">Inmate Name</span>
                                <span className="col col-lg-3 title">Location</span>
                            </div>
                            <div className="row">
                                <span className="col col-lg-3 field">{inmate && inmate.name ? inmate.name.trim() : "N/A"}</span>
                                <span className="col col-lg-3 field">{inmate && inmate.location ? inmate.location.trim() : "N/A"}</span>
                            </div>
                            <div className="row">
                                <span className="title">Tablet Condition</span>
                            </div>
                            <div className="row">
                                <div className="col-1 d-lg-none"></div>
                                <div className="psi-radio col col-lg-2">
                                    <label className="row" onClick={() => { setConditionGood(!conditionGood) }}>
                                        <span className="col">Good</span>
                                        <span className={"col psi-radio-btn" + ((conditionGood) ? " checked" : "")}></span>
                                    </label>
                                </div>
                                <div className="col-1"></div>
                                <div className="psi-radio col col-lg-2">
                                    <label className="row" onClick={() => { setConditionGood(!conditionGood) }}>
                                        <span className="col">Faulty</span>
                                        <span className={"col psi-radio-btn" + ((!conditionGood) ? " checked" : "")}></span>
                                    </label>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                        <div className="btn-container-horizontal row">
                            <button type="button" onClick={handleCancel} className="psi-btn psi-btn-default col">
                                Cancel
                            </button>
                            <div className="col-1"></div>
                            <button type="button" onClick={handleFirstConfirmClick} className="psi-btn psi-btn-primary col">
                                Confirm
                            </button>
                        </div>
                    </Modal.Body>
                </>
            }
            {pageNumber === 2 &&
                <>
                    <Modal.Header>
                        <Modal.Title>Damage Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="return-tablet-modal-body-container">
                            <div className="row">
                                <div className="col-12">Remove tablet from circulation?</div>
                                <div className="col-1 d-lg-none"></div>
                                <div className="psi-radio col col-lg-2">
                                    <label onClick={() => { setRemoveFromCirculation(!removeFromCirculation) }}>
                                        <span>Yes</span>
                                        <span className={"psi-radio-btn" + ((removeFromCirculation) ? " checked" : "")} style={{marginLeft: "20px"}}></span>
                                    </label>
                                </div>
                                <div className="col-1"></div>
                                <div className="psi-radio col col-lg-2">
                                    <label onClick={() => { setRemoveFromCirculation(!removeFromCirculation) }}>
                                        <span>No</span>
                                        <span className={"psi-radio-btn" + ((!removeFromCirculation) ? " checked" : "")} style={{marginLeft: "20px"}}></span>
                                    </label>
                                </div>
                                <div className="col-1"></div>
                            </div>
                            <div className="row" style={{marginTop: "20px"}}>
                                <div className="col-12">Was device damaged by inmate?</div>
                                <div className="col-1 d-lg-none"></div>
                                <div className="psi-radio col col-lg-2">
                                    <label onClick={() => { setDamagedByInmate(!damagedByInmate) }}>
                                        <span>Yes</span>
                                        <span className={"psi-radio-btn" + ((damagedByInmate) ? " checked" : "")} style={{marginLeft: "20px"}}></span>
                                    </label>
                                </div>
                                <div className="col-1"></div>
                                <div className="psi-radio col col-lg-2">
                                    <label onClick={() => { setDamagedByInmate(!damagedByInmate) }}>
                                        <span>No</span>
                                        <span className={"psi-radio-btn" + ((!damagedByInmate) ? " checked" : "")} style={{marginLeft: "20px"}}></span>
                                    </label>
                                </div>
                                <div className="col-1"></div>
                            </div>
                            <div className="row" style={{ marginTop: '36px' }}>
                                <span className="title">Damage Type</span>
                            </div>
                            <div className="row" style={{ marginTop: "2px" }}>
                                <span className="field">(select all that apply)</span>
                            </div>
                            {damageTypes && damageTypes.length > 0 ? damageTypes.map((damageType) => {
                                return (
                                    <div className="row" key={damageType.id} onClick={() => handleDamageTypeChange(damageType.id)}>
                                        <div className="col-1"></div>
                                        <span className={"psi-checkbox col" + ((damageType.checked) ? " checked" : "")} />
                                        {/* <span className={"psi-checkbox col" + ((damageType.checked) ? " checked" : "")} name={damageType.id} checked={!!damageType.checked} onChange={handleDamageTypeChange} /> */}
                                        <span className="col">{damageType.description}</span>
                                        <div className="col-1"></div>
                                    </div>
                                );
                            })
                                : <span>Loading...</span>}
                        </div>
                        <div className="btn-container-horizontal row">
                            <button type="button" onClick={handleBack} className="psi-btn psi-btn-empty col">
                                <span className="psi-icon icon-back inline-block"></span>
                                <span className="inline-block" style={{ paddingLeft: "12px" }}>Back</span>
                            </button>
                            <div className="col-1"></div>
                            <button type="button" onClick={handleReturnCircuit} className="psi-btn psi-btn-primary col" disabled={confirmDisabled}>
                                Confirm
                            </button>
                        </div>
                    </Modal.Body>
                </>
            }
        </Modal>
    );
}

export default ReturnCircuitModal;