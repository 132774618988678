import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

// Fix for the error that the library throws related to prop types.
// https://github.com/derrickpelletier/react-loading-overlay/pull/57
// @ts-ignore
LoadingOverlay.propTypes = undefined;

export interface ILoadingOverlayContext {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const LoadingOverlayContext = createContext({
  loading: false,
  setLoading: () => {}
} as ILoadingOverlayContext);

export const useLoadingOverlay = () => useContext(LoadingOverlayContext);

export const LoadingOverlayProvider = ({ children }: {children: React.ReactNode}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isLoading = () => {
      return loading;
    };

    isLoading();
  }, [loading]);

  return (
    <LoadingOverlayContext.Provider value={{ loading, setLoading }}>
        {/* @ts-ignore */}
        <LoadingOverlay active={loading} text="Loading..." spinner>
            {children}
        </LoadingOverlay>
    </LoadingOverlayContext.Provider>
  );
};