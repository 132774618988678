// import React, { useEffect, useState } from 'react';
// import useSharedService from '../services/SharedService';

const Home = () => {
  // const { testGetUsers } = useSharedService();
  // const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   const loadUsers = async () => {
  //     try {
  //       const result = await testGetUsers();
  //       setUsers(result.data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  //   loadUsers();
  // }, []);

  return (<></>
    // <div>
    //   <h1>Welcome to Tablet Manager!</h1>
    //   <ul>
    //     {
    //       (users.length > 0)
    //         ? users.map((user) => <li key={user.id}>{user.username}</li>)
    //         : <li>No users</li>
    //     }
    //   </ul>
    // </div>
  );
}

export default Home;