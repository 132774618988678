import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthService from "../services/AuthService";
import { useAuth } from "./hooks/AuthContext";
import { Form, FormGroup, FormFeedback, Label } from "reactstrap";
import { useLoadingOverlay } from "./hooks/LoadingOverlayContext";
import { LoginArgs } from "../models/args/LoginArgs";
import ForgotPasswordModal from "./ForgotPasswordModal";

class LoginForm {
    public username: string;
    public password: string;

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }
}

class LoginFormErrors {
    public username!: string;
    public password!: string;

    hasErrors(): boolean {
        return !!this.username || !!this.password;
    }
}

const Login = () => {
    const { login } = useAuthService();
    const { setAuth } = useAuth();
    const { loading, setLoading } = useLoadingOverlay();
    const navigate = useNavigate();
    const [form, setForm] = useState(new LoginForm("", ""));
    const [errors, setErrors] = useState({} as LoginFormErrors);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    

    useEffect(() => {
        // Sometimes when other pages redirect to here, the loading doesn't get set to false
        if (loading) {
            setLoading(false);
        }
        
        //setAuth(false);
    }, []);


    const handleForgotPasswordModalHide = () => {
        setField("username", "");
        setField("password", "");
        setShowForgotPasswordModal(false);
    }

    const setField = (field: string, value: string) => {
        setForm({
            ...form,
            [field]: value
        });

        // @ts-ignore
        if (errors[field]) {
            const newErrors = Object.assign(new LoginFormErrors(), errors);

            // Remove errors from field since it already exists
            // @ts-ignore
            newErrors[field] = null;
            setErrors(newErrors);
        }
    }

    const validateForm = () => {
        const { username, password } = form;
        const newErrors = new LoginFormErrors();

        if (!username) {
            newErrors.username = "please enter a Username";
        }

        if (!password) {
            newErrors.password = "please enter a Password";
        }

        return newErrors;
    }

    const handleLogin = async (e: any) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        const formErrors = validateForm();
        if (formErrors.hasErrors()) {
            setErrors(formErrors);
        } else {
            setLoading(true);

            const args: LoginArgs = {
                username: form.username,
                password: form.password
            };

            try {
                await login(args);
                setAuth(true);
                setLoading(false);
                navigate('/tablet');
            } catch (err: any) {
                console.error(err);
                let errMessage;
                switch (err.response.status) {
                    case 400:
                        errMessage = err.response.data;
                        break;
                    default:
                        errMessage = "Application Error";
                        break;
                }

                const newErrors = new LoginFormErrors();
                newErrors.username = errMessage;
                newErrors.password = errMessage;
                setErrors(newErrors);

                setLoading(false);
            }
        }
    }

    return (
        <>
        <Form className="login-page">
            <FormGroup className="field-container">
                <Label for="username" className={(errors.username) ? "psi-red" : ""}>Username{(errors.username) ? " - " + errors.username : ""}</Label>
                <div className="psi-text-field-container">
                <input
                    type="text"
                    placeholder="Username or Email"
                    className="form-control"
                    value={form.username}
                    onChange={(e) => {
                        setField("username", e.target.value);
                    }}
                    ref={usernameInputRef}
                ></input>
                <span
                    className="icon-cancel icon-inline"
                    onClick={(e) => {
                        setField("username", "");
                        
                        if (usernameInputRef.current) {
                            // @ts-ignore
                            usernameInputRef.current.focus();
                        }
                    }}
                />
                </div>
                <FormFeedback type="invalid" className="psi-red">
                    {errors.username}
                </FormFeedback>
            </FormGroup>
            <FormGroup className="field-container">
                <Label for="password" className={(errors.password) ? "psi-red" : ""}>Password{(errors.password) ? " - " + errors.password : ""}</Label>
                <div className="psi-text-field-container">
                    <input
                        type="password"
                        placeholder="Password"
                        className="form-control"
                        value={form.password}
                        onChange={(e) => { setField("password", e.target.value) }}
                        ref={passwordInputRef}
                    ></input>
                    <span
                        className="icon-cancel icon-inline"
                        onClick={() => {
                            setField("password", "");
                            if (passwordInputRef.current) {
                                // @ts-ignore
                                passwordInputRef.current.focus();
                            }
                        }}
                    />
                </div>
                <a className="forgot-password-link" onClick={() => {setShowForgotPasswordModal(true)}}>Forgot Password?</a>
                <FormFeedback type="invalid" className="psi-red">
                    {errors.password}
                </FormFeedback>
            </FormGroup>
            <div className="btn-container">
                <button type="submit" onClick={handleLogin} className="psi-btn psi-btn-primary">
                    Login
                </button>
            </div>
        </Form>
        <ForgotPasswordModal
            show={showForgotPasswordModal}
            setShowModal={setShowForgotPasswordModal}
            onHide={handleForgotPasswordModalHide}
        />
        </>
    );
}

export default Login;