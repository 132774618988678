import { AssignCircuitArgs } from "../models/args/AssignCircuitArgs";
import { ReturnCircuitArgs } from "../models/args/ReturnCircuitArgs";
import { Circuit } from "../models/dtos/Circuit";
import { CircuitAssignableResult } from "../models/dtos/CircuitAssignableResult";
import { TabletRental } from "../models/dtos/TabletRental";
import { TabletRentalDamageType } from "../models/dtos/TabletRentalDamageType";
import useBaseService from "./BaseService";

const useCircuitService = () => {
    const { get, post } = useBaseService();

    return {
        assignCircuit: async (args: AssignCircuitArgs): Promise<TabletRental> => {
            const response = await post('Circuit/AssignCircuit', args);
            return response?.data;
        },
        getAssignedCircuit: async (ani: string): Promise<TabletRental> => {
            const parameters = [{key: 'ani', value: ani}];
            const response = await get('Circuit/GetAssignedCircuit', parameters);
            return response?.data;
        },
        getCircuitAssignability: async (ani: string): Promise<CircuitAssignableResult> => {
            const parameters = [{key: 'ani', value: ani}];
            const response = await get('Circuit/GetAssignability', parameters);
            return response?.data;
        },
        getCircuitRentalDamageTypes: async (): Promise<TabletRentalDamageType[]> => {
            const response = await get('Circuit/GetCircuitRentalDamageTypes');
            return response?.data;
        },
        getCircuit: async (ani: string): Promise<Circuit> => {
            const parameters = [{key: 'ani', value: ani}];
            const response = await get('Circuit/GetCircuit', parameters);
            return response?.data;
        },
        getCircuits: async (): Promise<Circuit[]> => {
            const response = await get('Circuit');
            return response?.data;
        },
        returnCircuit: async (args: ReturnCircuitArgs) => {
            return await post('Circuit/ReturnCircuit', args);
        },
        searchCircuits: async (search: string, siteId: string | null): Promise<Circuit[]> => {
            const parameters = [{key: 'search', value: search}];
            
            if (siteId) {
                parameters.push({key: 'siteId', value: siteId});
            }

            const response = await get('Circuit/Search', parameters);
            return response?.data;
        }
    }
}

export default useCircuitService;