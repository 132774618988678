import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuthService from "../services/AuthService";
import { Form, FormGroup, FormFeedback, Label, Input } from "reactstrap";
import { useLoadingOverlay } from "./hooks/LoadingOverlayContext";
import { ResetPasswordArgs } from "../models/args/ResetPasswordArgs";
import { toast } from "react-toastify";
import { Domain } from "../models/dtos/Domain";

class ResetPasswordForm {
    public password!: string;
    public passwordConfirm!: string;
}

class ResetPasswordFormErrors {
    public password!: string;
    public passwordConfirm!: string;

    hasErrors(): boolean {
        return !!this.password || !!this.passwordConfirm;
    }
}

const ResetPassword = () => {
    const { token } = useParams();
    
    const { getDomainByToken, resetPassword } = useAuthService();
    const { loading, setLoading } = useLoadingOverlay();
    const navigate = useNavigate();
    const [form, setForm] = useState({} as ResetPasswordForm);
    const [errors, setErrors] = useState({} as ResetPasswordFormErrors);
    const [domain, setDomain] = useState({} as Domain);

    useEffect(() => {
        const loadDomain = async () => {
            try {
                if (token) {
                    setLoading(true);
                    const domain = await getDomainByToken(token);
                    setDomain(domain);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        }

        loadDomain();
    }, []);

    const setField = (field: string, value: string) => {
        setForm({
            ...form,
            [field]: value
        });

        // @ts-ignore
        if (errors[field]) {
            const newErrors = Object.assign(new ResetPasswordFormErrors(), errors);

            // Remove errors from field since it already exists
            // @ts-ignore
            newErrors[field] = null;
            setErrors(newErrors);
        }
    }

    const validateForm = (): ResetPasswordFormErrors => {
        const { password, passwordConfirm } = form;
        const newErrors = new ResetPasswordFormErrors();

        if (password && passwordConfirm) {
            if (password !== passwordConfirm) {
                newErrors.password = "passwords do not match";
                newErrors.passwordConfirm = "passwords do not match";
            } else {
                let errorList = [];
                if (domain.passwordCapital) {
                    const pattern = new RegExp('^(?=.*[A-Z])');
                    if (!pattern.test(password)) {
                        errorList.push('password must contain a capital letter');
                    }
                }

                if (domain.passwordNumber) {
                    const pattern = new RegExp('^(?=.*[0-9])');
                    if (!pattern.test(password)) {
                        errorList.push('password must contain a number');
                    }
                }

                if (domain.passwordSpecial) {
                    const pattern = new RegExp('^(?=.*[!@#\$%\^&\*])');
                    if (!pattern.test(password)) {
                        errorList.push('password must contain a special character');
                    }
                }

                if (domain.passwordLength && domain.passwordLength !== 0) {
                    const expression = '^(?=.{' + domain.passwordLength + ',})';
                    const pattern = new RegExp(expression);
                    if (!pattern.test(password)) {
                        errorList.push('password must be at least ' + domain.passwordLength + ' characters');
                    }
                }

                if (errorList.length > 0) {
                    let errorMessage = "";
                    for (let i = 0; i < errorList.length; i++) {
                        if (i == 0) {
                            errorMessage = errorList[i];
                        } else {
                            errorMessage += " & " + errorList[i];
                        }
                    }

                    newErrors.password = errorMessage;
                    //newErrors.passwordConfirm = errorMessage;
                }
            }
        } else {
            if (!password) {
                newErrors.password = "please enter a Password";
            }
    
            if (!passwordConfirm) {
                newErrors.passwordConfirm = "please confirm your password";
            }
        }

        return newErrors;
    }

    const handleResetPassword = async (e: any) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        const formErrors = validateForm();
        if (formErrors.hasErrors()) {
            setErrors(formErrors);
        } else {
            setLoading(true);

            if (!token) {
                toast.error("Invalid token");
                setLoading(false);
                return;
            }

            const args: ResetPasswordArgs = {
                password: form.password,
                token: token
            };

            try {
                await resetPassword(args);
                setLoading(false);
                toast.success("Password reset successfully!");
                navigate('/login');
            } catch (err: any) {
                console.error(err);
                toast.error("Failed to reset password");
                setLoading(false);
            }
        }
    }

    return (
        <Form className="login-page">
            <FormGroup className="field-container">
                <Label for="password" className={(errors.password) ? "psi-red" : ""}>Password{(errors.password) ? " - " + errors.password : ""}</Label>
                <Input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    defaultValue={form.password}
                    onChange={(e) => { setField("password", e.target.value) }}
                ></Input>
                <FormFeedback type="invalid" className="psi-red">
                    {errors.password}
                </FormFeedback>
            </FormGroup>
            <FormGroup className="field-container">
                <Label for="passwordConfirm" className={(errors.passwordConfirm) ? "psi-red" : ""}>Confirm Password{(errors.passwordConfirm) ? " - " + errors.passwordConfirm : ""}</Label>
                <Input
                    type="password"
                    placeholder="Confirm Password"
                    className="form-control"
                    defaultValue={form.passwordConfirm}
                    onChange={(e) => { setField("passwordConfirm", e.target.value) }}
                ></Input>
                <FormFeedback type="invalid" className="psi-red">
                    {errors.passwordConfirm}
                </FormFeedback>
            </FormGroup>
            <div className="btn-container">
                <button type="submit" onClick={handleResetPassword} className="psi-btn psi-btn-primary">
                    Reset Password
                </button>
            </div>
        </Form>
    );
}

export default ResetPassword;